<template>
  <div v-if="dealer" class="world leads-page">
    <div class="wrapper-title">
      <div class="container">
        <router-link :to="{name: 'ww.dashboard'}" class="btn-previous">&larr; {{ $t('Back to overview') }}</router-link>
        <div class="flex">
          <div>
            <h1>{{ $t('Leads') }}</h1>

            <div class="filters">
              <formulate-input :placeholder="$t('Zoeken')" @input="fetchLeads" type="text" v-model="leadsSearch"/>
              <formulate-input v-model="leadsArchived" @input="fetchLeads" type="checkbox" :label="$t('Toon gearchiveerde')"></formulate-input>
            </div>
          </div>
          <div class="buttons">
            <router-link :to="{name: 'ww.leads.detail.new'}" class="btn">{{ $t('Nieuwe lead') }}</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-content">
      <div class="leads-wrapper">
        <div class="container">
          <div v-if="leads.length > 0" class="table-wrapper leads">
            <div class="table-element">
              <table>
                <thead>
                <tr>
                  <th>{{ $t('Klant') }}</th>
                  <th>{{ $t('Kanaal') }}</th>
                  <th>{{ $t('Gemeente') }}</th>
<!--                  <th>{{ $t('Bedrag offerte') }}</th>-->
                  <th>{{ $t('Type') }}</th>
                  <th>{{ $t('Producten') }}</th>
                  <th>{{ $t('Datum') }}</th>
                  <th>{{ $t('Status') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="lead in leads" :key="lead.id" class="lead" @click="$router.push({name: 'ww.leads.detail', params: { id: lead.id }})" @contextmenu.prevent.stop="leadRightClick($event, lead)">
                  <td>{{ lead.first_name }} {{ lead.last_name }}</td>
                  <td>{{ $t('leads_channel_' + lead.channel.channel) }}</td>
                  <td>{{ lead.city }}</td>
<!--                  <td>-->
<!--                    <span v-if="lead.latest_quote">{{ $n(lead.latest_quote.calculated_total_without_vat, 'currency', 'nl-BE') }}</span>-->
<!--                  </td>-->
                  <td>{{ $t('leads_type_' + lead.type) }}</td>
                  <td>
                    <div class="icons" v-if="lead.products && lead.products.filter(product => product !== 'none').length > 0">
                      <span class="icon" :key="'product' + product" v-for="product in lead.products.filter(product => product !== 'none')">
                        <img width="30" :src="require('@/assets/img/' + product + '.svg')" :alt="product">
                      </span>
                    </div>
                    <div v-else>/</div>
                  </td>
                  <td>{{ datetime.fromISO(lead.created).setLocale(activeLocale).toFormat('dd/LL/yyyy') }}</td>
                  <td>
                    <span :class="'status status-' + lead.status.status">
                      {{ $t('leads_status_' + lead.status.status) }}
                      <template v-if="lead.status.status === 'to_follow_up'">
                        {{ datetime.fromISO(lead.status_followup_date).setLocale(activeLocale).toFormat('dd/LL/yyyy') }}
                      </template>
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>{{ $t('Er zijn momenteel nog geen leads.') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { DateTime } from 'luxon'

export default {
  data: function () {
    return {
      leadsSearch: '',
      leads: [],
      leadsTotal: null,
      leadsChannels: {},
      leadsStatuses: {},
      datetime: DateTime,
      leadsArchived: true,
      statusSortOrder: {
        new: 0,
        to_follow_up: 1,
        in_progress: 2,
        won: 3,
        installed: 4,
        lost: 5
      }
    }
  },
  async mounted () {
    await this.setDealer()
    await ApiService.fetchLeadsChannels().then(res => {
      for (const channel of res.data) {
        this.leadsChannels[channel.channel] = channel
      }
    })
    await ApiService.fetchLeadsStatuses().then(res => {
      for (const status of res.data) {
        this.leadsChannels[status.status] = status
      }
    })
    await this.fetchLeads()
  },
  methods: {
    async setArchived () {
      this.leadsArchived = !this.leadsArchived

      await this.fetchLeads()
    },
    async fetchLeads () {
      await ApiService.fetchDealerLeads(this.dealer.id, {
        archived: this.leadsArchived,
        search: this.leadsSearch
      }).then(res => {
        this.leads = res.data.leads.sort((a, b) => this.statusSortOrder[a.status.status] - this.statusSortOrder[b.status.status])
        this.leadsTotal = res.data.total
      })
    },
    leadRightClick (event, lead) {

    }
  }
}
</script>
